function toggleFAQ(event) {
  document.querySelector('.faq').classList.toggle('disabled')
  if (event.target.textContent === 'Часто задаваемые вопросы') {
    event.target.textContent = 'Скрыть вопросы'
  } else {
    event.target.textContent = 'Часто задаваемые вопросы'
  }
}

function onHelpButtonClick(event) {
  if (!event.classList.contains('helpvictimbutton')) {
    event.parentElement
      .querySelector('.choose-method-field')
      .classList.toggle('disabled')
    event.classList.toggle('disabled')
  } else {
    event.parentElement.parentElement
      .querySelector('.victiminfo')
      .classList.toggle('disabled')
    event.parentElement.parentElement
      .querySelector('.choose-method-field')
      .classList.toggle('disabled')
    event.parentElement.parentElement
      .querySelector('.victimimage')
      .classList.toggle('disabled')
    event.classList.toggle('disabled')
  }
}

function onBackButtonClick(event) {
  if (!event.classList.contains('modaltotalback')) {
    event.parentElement.classList.add('disabled')
    event.parentElement.parentElement.parentElement
      .querySelector('.helpbutton')
      .classList.remove('disabled')
  } else {
    event.parentElement.parentElement
      .querySelector('.victiminfo')
      .classList.toggle('disabled')
    event.parentElement.classList.add('disabled')
    event.parentElement.parentElement.parentElement
      .querySelector('.helpbutton')
      .classList.remove('disabled')
    event.parentElement.parentElement
      .querySelector('.victimimage')
      .classList.toggle('disabled')
  }
}

function onChooseFieldBack(event) {
  event.parentElement.classList.add('disabled')
  event.parentElement.parentElement
    .querySelector('.choose-method-field')
    .classList.remove('disabled')
}

function showCards(event) {
  event.parentElement.classList.add('disabled')
  event.parentElement.parentElement
    .querySelector('.choose-method-field-banks')
    .classList.remove('disabled')
}

function btcShow(event) {
  event.parentElement.classList.add('disabled')
  event.parentElement.parentElement
    .querySelector('.choose-method-field-btc')
    .classList.remove('disabled')
}

function victimModalShow(event) {
  let targ = event.target.parentElement

  while (targ.classList[0] !== 'personItemWrapper') {
    targ = targ.parentElement
  }

  targ
    .querySelector('.victimbg')
    .classList.remove('disabled')
}

function victimModalCloseByButton(event) {
  event.parentElement.parentElement.classList.add('disabled')
}

function victimModalClose(event) {
  if (event.target.classList[0] === 'victimbg')
    event.target.classList.add('disabled')
}

function victimNamePartition(event) {
  const text = event.textContent
  const [name, surname] = text.split(' ')
  event.textContent = ''
  event.innerHTML = `<span class="name firstname">${name}</span>
  <span class="name">${surname}</span>`
  event.addEventListener('click', (event) => victimModalShow(event))
}

window.addEventListener('load', () => {
  const questionsButton = document.querySelector('#questionsbutton')
  const helpButtons = document.querySelectorAll('.helpbutton')
  const totalBackButtons = document.querySelectorAll('.totalback')
  const backButtons = document.querySelectorAll('.firstfield')
  const showCardsButtons = document.querySelectorAll('.showcardsbutton')
  const bitcoinShowButtons = document.querySelectorAll('.btcshow')
  const victimbg = document.querySelectorAll('.victimbg')
  const persitemname = document.querySelectorAll('.canBeClickedToShowModal')
  const closeModalButtons = document.querySelectorAll('.closeModalButton')
  const victimNameFields = document.querySelectorAll('.victimNameField')

  victimNameFields.forEach((event) => victimNamePartition(event))

  questionsButton.addEventListener('click', (event) => toggleFAQ(event))
  helpButtons.forEach((button) =>
    button.addEventListener('click', () => onHelpButtonClick(button))
  )
  totalBackButtons.forEach((button) =>
    button.addEventListener('click', () => onBackButtonClick(button))
  )
  backButtons.forEach((button) =>
    button.addEventListener('click', () => onChooseFieldBack(button))
  )
  showCardsButtons.forEach((button) =>
    button.addEventListener('click', () => showCards(button))
  )
  bitcoinShowButtons.forEach((button) =>
    button.addEventListener('click', () => btcShow(button))
  )
  victimbg.forEach((link) =>
    link.addEventListener('click', (event) => victimModalClose(event))
  )
  persitemname.forEach((name) =>
    name.addEventListener('click', (event) => victimModalShow(event))
  )
  closeModalButtons.forEach((button) =>
    button.addEventListener('click', () => victimModalCloseByButton(button))
  )
})


// Убираем фокус у тех, кто не нажимал таб
document.addEventListener('DOMContentLoaded', function onDOMContentLoaded() {
  document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);

  let body = document.body;

  body.classList.add('_noFocusHighlight');

  document.addEventListener('keydown', (evt) => {
    if (evt.which == 9 /* Tab */) {
      body.classList.remove('_noFocusHighlight');
    }
  });

  document.addEventListener('click', () => {
    if (document.activeElement == body) {
      body.classList.add('_noFocusHighlight');
    }
  });
});
